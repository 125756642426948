const de = {
  globals: {
    buttons: {
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      save: 'Speichern',
      cancel: 'Abbrechen',
    },
    titles: {
      currentWindow: 'Selbe Fenster',
      newWindow: 'Neues Fenster',
    },
    words: {
      total: 'Total',
      standard: 'Standard',
      detailed: 'Detailliert',
      last7Days: 'Letzen 7 Tage',
      last14Days: 'Letzen 14 Tage',
      last30Days: 'Letzen 30 Tage',
      last90Days: 'Letzen 90 Tage',
      actualMonth: 'Aktueller Monat',
      lastMonth: 'Letzter Monat',
      last3Months: 'Letzten 3 Monate',
      day: 'Tag',
      days: 'Tage',
      readMore: 'Weiterlesen',
    },
    labels: {
      navigationTitle: 'Navigationstitel',
      icon: 'Icon',
      target: 'Ziel',
      url: 'URL',
    },
    placeholders: {
      navigationTitle: 'Navigationstitel eingeben',
      icon: 'Icon suchen',
      target: 'Ziel auswählen',
      url: 'URL eingeben',
    },
    icons: {
      titles: {
        gear: 'Zahnrad',
        hammer: 'Hammer',
        wrench: 'Schlüssel',
        hardHat: 'Schutzhelm',
        excavator: 'Bagger',
        fireExtinguisher: 'Feuerlöscher',
        hospital: 'Krankenhaus',
        ambulance: 'Krankenwagen',
        heartbeat: 'Herzschlag',
        medkit: 'Medizinkasten',
        pills: 'Pillen',
        syringe: 'Spritze',
        userDoctor: 'Arzt',
        tooth: 'Zahn',
        graduationCap: 'Abschlussmütze',
        book: 'Buch',
        chalkboardTeacher: 'Lehrer am schwarzen Brett',
        laptop: 'Laptop',
        mobileAlt: 'Mobiles Gerät',
        camera: 'Kamera',
        film: 'Film',
        music: 'Musik',
        gamepad: 'Spielcontroller',
        basketballBall: 'Basketball',
        footballBall: 'Fußball',
        bicycle: 'Fahrrad',
        car: 'Auto',
        bus: 'Bus',
        plane: 'Flugzeug',
        train: 'Zug',
        ship: 'Schiff',
        home: 'Haus',
        building: 'Gebäude',
        city: 'Stadt',
        shoppingCart: 'Einkaufswagen',
        moneyBillWave: 'Geldschein',
        chartBar: 'Balkendiagramm',
        chartPie: 'Kreisdiagramm',
        chartLine: 'Liniendiagramm',
        users: 'Benutzer',
        user: 'Benutzer',
        cog: 'Zahnrad',
        bell: 'Glocke',
        envelope: 'Umschlag',
        lock: 'Schloss',
        eye: 'Auge',
        eyeSlash: 'Durchgestrichenes Auge',
        upload: 'Hochladen',
        download: 'Herunterladen',
        trash: 'Müll',
        cameraRetro: 'Retro-Kamera',
        cloud: 'Wolke',
        cloudSun: 'Wolke mit Sonne',
        cloudMoon: 'Wolke mit Mond',
        snowflake: 'Schneeflocke',
        smile: 'Lächeln',
        sadCry: 'Weinen',
        thumbsUp: 'Daumen hoch',
        thumbsDown: 'Daumen runter',
        lightbulb: 'Glühbirne',
        rocket: 'Rakete',
        calendar: 'Kalender',
        clock: 'Uhr',
        map: 'Karte',
        star: 'Stern',
        heart: 'Herz',
        comment: 'Kommentar',
        share: 'Teilen',
      },
      tags: {
        tools: 'Werkzeuge',
        construction: 'Bau',
        mechanic: 'Mechaniker',
        safety: 'Sicherheit',
        machines: 'Maschinen',
        vehicles: 'Fahrzeuge',
        fire: 'Feuer',
        emergency: 'Notfall',
        health: 'Gesundheit',
        medical: 'Medizinisch',
        fitness: 'Fitness',
        pharmacy: 'Apotheke',
        vaccine: 'Impfung',
        doctor: 'Arzt',
        dental: 'Zahnmedizin',
        education: 'Bildung',
        school: 'Schule',
        learning: 'Lernen',
        technology: 'Technologie',
        computer: 'Computer',
        work: 'Arbeit',
        mobile: 'Mobil',
        communication: 'Kommunikation',
        photography: 'Fotografie',
        media: 'Medien',
        entertainment: 'Unterhaltung',
        gaming: 'Spiele',
        sports: 'Sport',
        basketball: 'Basketball',
        recreation: 'Freizeit',
        transport: 'Transport',
        public: 'Öffentlich',
        travel: 'Reise',
        urban: 'Städtisch',
        town: 'Stadt',
        location: 'Standort',
        shopping: 'Einkaufen',
        retail: 'Einzelhandel',
        purchase: 'Kauf',
        finance: 'Finanzen',
        money: 'Geld',
        economy: 'Wirtschaft',
        business: 'Geschäft',
        data: 'Daten',
        analytics: 'Analytik',
        people: 'Menschen',
        community: 'Gemeinschaft',
        social: 'Sozial',
        profile: 'Profil',
        settings: 'Einstellungen',
        gear: 'Zahnrad',
        notifications: 'Benachrichtigungen',
        alert: 'Alarm',
        messages: 'Nachrichten',
        security: 'Sicherheit',
        protection: 'Schutz',
        visibility: 'Sichtbarkeit',
        see: 'Sehen',
        hide: 'Verstecken',
        files: 'Dateien',
        delete: 'Löschen',
        vintage: 'Vintage',
        weather: 'Wetter',
        nature: 'Natur',
        sun: 'Sonne',
        moon: 'Mond',
        winter: 'Winter',
        emotions: 'Emotionen',
        happiness: 'Glück',
        sadness: 'Traurigkeit',
        approval: 'Genehmigung',
        disapproval: 'Missbilligung',
        ideas: 'Ideen',
        inspiration: 'Inspiration',
        space: 'Raum',
        launch: 'Start',
        time: 'Zeit',
        schedule: 'Zeitplan',
        maps: 'Karten',
        navigation: 'Navigation',
        rating: 'Bewertung',
        favorites: 'Favoriten',
        comments: 'Kommentare',
        feedback: 'Rückmeldung',
      },
    },
  },
  pages: {
    login: {
      labels: {
        username: 'Benutzername',
        password: 'Passwort',
        totp: 'Zifferncode',
        autologin: 'Eingeloggt bleiben?',
        submit: {
          credentials: 'Login',
          totp: 'Bestätigen',
        },
      },
      links: {
        passwordReset: 'Passwort vergessen?',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
      },
      totp: {
        description:
          'Als zusätzliche Sicherheitsmaßnahme geben Sie bitte die von Ihrer Authenticator-App generierte, Ziffernfolge ein.',
      },
      validation: {
        usernameRequired: 'Der Benutzername ist ein Pflichtfeld.',
        passwordRequired: 'Das Passwort ist ein Pflichtfeld.',
        invalidCredentials: 'Benutzername oder Passwort ungültig.',
        totpRequired: 'TOTP ist ein Pflichtfeld.',
        sessionStartFailed: 'Es gab ein Problem bei der Anmeldung. Bitte versuchen Sie es später noch einmal.',
      },
    },
    project: {
      header: {
        projectAddress: 'LV Adresse',
        projectStatus: 'Projekt Status',
        billingType: 'Abrechnung',
        responsibleProjectManager: 'Bauleiter',
        startOfConstruction: 'Baubeginn',
        endOfConstruction: 'Bauende',
        progress: 'Progress',
        //
        totalTime: 'Gesamtzeit',
        alreadyCompleted: 'Bereits erledigt',
        unproductive: 'Unproduktiv',
        unproductiveTime: 'Unproduktive Zeiten',
        productiveTime: 'Produktive Zeiten',
        nonContractual: 'NVA',
        nonContractualTime: 'Nicht vertragliche Arbeiten',
        contractualTimeOriginalPlan: 'Kalkulierte Auftragszeit (Ursprungsplan)',
        addendum: 'Nachtrag',
        deletedPositions: 'Gelöschte Positionen',
        addedUnits: 'Mehrmenge',
        reducedUnits: 'Mindermenge',
        differenceToOriginalCalculation: 'Differenz zur Urkalkulation (Aktueller Plan)',
        currentOrderVolume: 'Aktuelles Auftragsvolumen',
        PAC: 'PIV',
        toBeDone: 'Noch zu erledigen',
        differenceToActualPlan: 'Differenz zum aktuellen Plan',
        differenceToOriginalPlan: 'Differenz zur Ursprungsplan',
        open: 'Offen',
        //
        details: 'Details',
        PACTotal: 'Plan-Ist-Vergleich (PIV)',
        last7Days: 'Letzten 7 Tage',
        process: 'Verlauf',
        //
        valuable: 'Abrechenbar',
        nonBillable: 'Nicht Abrechenbar',
        nonValuable: 'Nicht Bewertet',
        //
        travelTime: 'Wegezeiten',
        setupTime: 'Rüstzeiten',
        otherUnproductiveTime: 'Weitere Unpro. Lohnart',
        //
        totalOverview: 'Gesamtübersicht',
        times: 'Zeiten',
        hours: 'Stunden',
        factor: 'Faktor',
        percentage: 'Anteil in %',
        originalOrderTime: 'Ursprüngliche Auftragszeit',
        //
        h: 'Std.',
        //
        PACDetails: 'PIV Details',
      },
    },
    dashboard: {
      labels: {
        dailyPlanning: 'Tagesplanung',
        news: 'Nachrichten',
        timeRecording: 'Zeiterfassung',
        dailyReports: 'Tagesberichte',
        photoDocumentation: 'Fotodokumentation',
        POBox: 'Postfach',
        tasks: 'Aufgaben',
        nonContractualWork: 'Nicht vertragliche Arbeiten',
        quickLinks: 'Quick Links',
        vacations: 'Urlaub',
      },
      vacations: {
        currentYearClaim: 'Anspruch',
        lastYearClaim: 'Aus vor Jahr',
        totalClaim: 'Gesamtanspruch',
        taken: 'Genommen',
        planned: 'Geplant',
        requested: 'Beantragt',
        rest: 'Rest',
      },
    },
    linkModule: {
      title: 'Link Modul',
    },
  },
  modals: {
    linkModule: {
      addTitle: 'Link Anliegen',
      editTitle: 'Link Bearbeiten',
    },
    iconPicker: {
      title: 'Icon auswählen',
    },
    delete: {
      title: 'Sind Sie sicher?',
      body: 'Sind Sie sicher, dass Sie diesen Artikel löschen wollen? Diese Aktion ist nicht umkehrbar!',
    },
  },
  errors: {
    required: 'Dieses Feld ist erforderlich',
    invalidURL: 'Ungültiges URL-Format',
  },
};

export default de;
