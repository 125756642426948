import dayjs from 'dayjs';
import { Environment } from '~/enums/Environment';
import { getDateFormat } from './date-formatter';

// env and API
export const isEnvironment = (...environments: Environment[]): boolean => {
  for (let i = 0; i < environments.length; i++) {
    if (REACT_APP_ENV === environments[i]) {
      return true;
    }
  }

  return false;
};

export const isLocal = () => {
  return isEnvironment(Environment.LOCAL);
};

const galaworkApiTokenKey = 'galawork_api_token';

export const getGalaworkApiToken = (): string | null => {
  const jwtToken = localStorage.getItem(galaworkApiTokenKey);

  if (typeof jwtToken === 'undefined') {
    return null;
  }

  return jwtToken;
};

export const setGalaworkApiToken = (jwtToken: string) => {
  localStorage.setItem(galaworkApiTokenKey, jwtToken);
};

// date and time
export const formatDate = (dateString: string | null | undefined): string => {
  if (dateString && dayjs(dateString).isValid()) {
    return dayjs(dateString).format(getDateFormat());
  }
  return '—';
};

export const convertTimeInSeconds = (
  timeInSeconds: number | null | undefined
): { hours: number; minutes: number; seconds: number } => {
  const validTimeInSeconds = timeInSeconds ?? 0;
  const absTimeInSeconds = Math.abs(validTimeInSeconds);
  const hours = Math.floor(absTimeInSeconds / 3600);
  const minutes = Math.floor((absTimeInSeconds % 3600) / 60);
  const seconds = absTimeInSeconds % 60;

  return {
    hours: validTimeInSeconds < 0 && hours !== 0 ? -hours : hours === 0 ? 0 : hours,
    minutes: validTimeInSeconds < 0 && minutes !== 0 ? -minutes : minutes === 0 ? 0 : minutes,
    seconds: validTimeInSeconds < 0 && seconds !== 0 ? -seconds : seconds === 0 ? 0 : seconds,
  };
};

export const secondsToHours = (seconds: number): number => seconds / 3600;

// general
export function insertAt<T>(array: T[], index: number, item: T): T[] {
  // Create a new array with the item inserted at the specified index
  return [...array.slice(0, index), item, ...array.slice(index)];
}
